var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c(
        "vx-card",
        { staticClass: "py-4", attrs: { title: "" + _vm.model.name } },
        [
          _c("questionnaire-answers", {
            attrs: { id: _vm.id, content: _vm.model },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }