<template>
  <vx-card class="py-4" v-if="model" :title="`${model.name}`">
    <questionnaire-answers
      :id="id"
      :content="model"
    />
  </vx-card>
</template>

<script>
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import QuestionnaireAnswers from './elements/QuestionnaireAnswers.vue'
export default {
  components: { QuestionnaireAnswers },

  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: {},
    model: null,
  }),

  computed: {
    contentData() {
      return JSON.parse(this.model.data)
    }
  },

  created() {
    this.service = ContentQuestionnaireService.build()
  },

  mounted() {
    this.$vs.loading()
    this.service.get(this.id).then(
      (data) => {
        this.$vs.loading.close()
        this.model = data
      },
      (error) => {
        console.error(error)
        this.$vs.loading.close()
      }
    )
  }
}
</script>